#appcontainer {
    overflow: hidden;
    height: 100%;
    overflow: hidden;
}

#appcanvas {
    max-width: 640px;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}

#headerImage {
    width: 100%;
    height: 192px;
}

header {
    position: 'relative'
}

nav {
    text-align: center;
}


.appheader_text {
    text-align: center;
    height: 64px;
    line-height: 64px;
}

.block_text {
    margin: 16px 16px 16px 16px;
}

.block_image {
    max-width: 100%;
}

button {
    border: 0;
    outline: 0;
}

#appdialog {
    height: 100%;
    width: 100%;
    position: fixed;
    /* Stay in place */
    z-index: 60;
    /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.7);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    overflow-y: hidden;
    /* Disable horizontal scroll */
}

.component-tile {
    background-color: aqua;
}